<template>
  <div>
    <vx-card
      :title="`📦📦📦 Importador masivo de ${$tc(
        'message.warehouse',
        2,
        user.countryCode
      )}.`"
      title-color="black"
    >
      <p class="mb-1">
        Si desea un archivo base con las columnas necesarias, puede descargar el
        archivo pulsando el botón de archivo base.
      </p>
      <p>
        🏸 Verfique el archivo base, en el cual encontrara un ejemplo de como
        ingresar sus propiedades de forma fácil.
      </p>

      <vs-button
        color="primary"
        icon-pack="fas"
        icon="fa-file-excel"
        class="m-2 mt-4"
        href="https://flattlo-app.s3.amazonaws.com/base-download-files/BODEGAS_Flattlo.xlsx"
        type="flat"
        >&nbsp; Descargar archivo base</vs-button
      >
    </vx-card>
    <br />
    <div class="mb-8">
      <ExcelBatchImporter :onSuccess="loadDataInTable" />
    </div>
    <vx-card
      v-if="tableData.length && header.length"
      title="📁📃 Carga correcta."
      title-color="black"
    >
      <p>
        Se han encontrado <strong>{{ tableData.length }}</strong> registros.
      </p>
      <vs-button
        @click="uploadWarehouses()"
        color="success"
        class="mt-4"
        type="filled"
        icon-pack="fas"
        icon="fa-save"
        >Guardar {{ $tc("message.warehouse", 1, user.countryCode) }}</vs-button
      >
      <br />
      <vs-progress
        v-if="statusBar > 0"
        :height="12"
        :percent="statusBar"
        color="success"
      ></vs-progress>
      <p v-if="statusBar > 0">
        Importado correcto de <strong>{{ statusBar }}%</strong>
      </p>
    </vx-card>
    <br />
    <vx-card v-if="tableData.length && header.length">
      <vs-table stripe :max-items="50" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{
            heading
          }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              :data="col"
              v-for="(col, index) in data[indextr]"
              :key="index"
            >
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ExcelBatchImporter from "../../../components/ExcelBatchImporter.vue";
import { propertyApi, batch } from "../services";
import { capitalize } from "../../../helpers/capitalize";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  components: {
    ExcelBatchImporter,
  },
  data() {
    return {
      tableData: [],
      header: [],
      sheetName: "",
      statusBar: 0,
      propertyStatus: [],
    };
  },
  async mounted() {
    this.propertyStatus = await propertyApi.getPropertyStatus();
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    loadDataInTable({ results, header, meta }) {
      this.header = header;
      this.tableData = results;
      this.sheetName = meta.sheetName;
    },
    async uploadWarehouses() {
      try {
        const projectId = this.$store.state.project.currentProjectId;
        const warehouses = this.tableData.map(async (element) => {
          const statusBarIncrement =
            Math.round((100 / this.tableData.length) * 100) / 100;

          const statusCapitalize = capitalize(
            element.estado.toLowerCase().trim()
          );

          const status =
            this.propertyStatus.find(
              (element) => element.name === statusCapitalize
            ) ||
            this.propertyStatus.find(
              (element) => element.name === "Disponible"
            );

          try {
            await batch.importBatch(projectId, {
              type: "WAREHOUSE",
              status: status ? status.id: "BLOCKED",
              unitNumber: element.numero.toString().trim(),
              level: element.nivel ? element.nivel.toString().trim() : "1",
              tower: element.torre ? element.torre.toString().trim(): null,
              price: parseFloat(element.precio),
              totalArea: parseFloat(element.m2),
            });
          } catch (error) {
            console.error(`Error con la propiedad ${element.numero}`);
            console.error(error);
          }

          this.statusBar =
            Math.round((this.statusBar + statusBarIncrement) * 100) / 100;
        });

        await Promise.all(warehouses);

        this.$vs.notify({
          time: 6000,
          title: `Batch importado! 🏢🚀`,
          text: `Las propiedades fueron creadas correctamente.`,
          color: "success",
        });

        this.$router.push({
          name: "Bodegas",
          params: {
            projectId,
          },
        });
      } catch (e) {
        sentryCaptureException(e, "warehouse-importer");
        this.statusBar = 0;
        this.$vs.notify({
          time: 6000,
          title: `Error`,
          text: `Hubo un error cargando los cuartos útiles, por favor revisar el formato del archivo.`,
          color: "danger",
        });
      }
    },
  },
};
</script>
